<template>
  <div class="ontmoet-schalk">
    <div class="container">
      <div class="row">
        <div class="col-md-6 offset-md-6">
          <div class="ontmoet-schalk-text rounded-3 text-brand-primary" id="ontmoetschalk">
            <h1>{{ $t('ontmoet_schalk.title') }}</h1>
            <p>{{ $t('ontmoet_schalk.description') }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
  export default {
    name: "OntmoetSchalkAfr",
  };
  </script>
  
  <style scoped>
  .ontmoet-schalk {
    /* Replace the background-image URL with the actual image URL */
    background-image: url('~@/assets/img/bg_ontmoet.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 100px 0;
  }
  
  .ontmoet-schalk-text {
    background-color: #fff;
    padding: 40px;
  }
  </style>
  