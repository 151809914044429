<template>
  <section>
    <div class="container-fluid bg-brand-primary text-white">
      <div class="container p-5">
        <div class="row">
          <!-- Column 1 -->
          <div class="col-md-6">
            <!-- Content for Column 2 -->
            <div class="contact-item">
              <div class="contact-icon"><i class="bi bi-envelope fs-1"></i></div>
              <div class="contact-details">

                <p>admin@schalkburger.co.za</p>
              </div>
            </div>

            <div class="contact-item">
              <div class="contact-icon"><i class="bi bi-telephone fs-1"></i></div>
              <div class="contact-details">

                <p>086 100 7272</p>
              </div>
            </div>

            <div class="contact-item">
              <div class="contact-icon"><i class="bi bi-geo-alt fs-1"></i></div>
              <div class="contact-details">

                <p>233 Mooiplaats, R631 Road,<br> Zwavelpoort, Pretoria 0181</p>
              </div>
            </div>


            <!-- Add any other content for Column 2 -->
          </div>
          <!-- Column 2 -->
          <div class="col-md-6">
            <h1><span class="fw-light">{{ $t('kontak_ons.title_light') }}</span>{{ $t('kontak_ons.title') }}</h1>
            <form class="" method="POST" action="src\api\contact_handler.php">
              <!-- Your form fields for Column 1 -->
              <div class="form-group">
                <label :for="$t('kontak_ons.form.naam')">{{ $t('kontak_ons.form.naam') }}</label>
                <input type="text" id="naam" name="name" class="form-control"  required/>
              </div>
              <div class="form-group">
                <label :for="$t('kontak_ons.form.van')">{{ $t('kontak_ons.form.van') }}</label>
                <input type="text" id="van" name="surname" class="form-control" required/>
              </div>
              <div class="form-group">
                <label :for="$t('kontak_ons.form.telefoonNommer')">{{ $t('kontak_ons.form.telefoonNommer') }}</label>
                <input type="text" id="telefoonNommer" name="phone" class="form-control" required />
              </div>
              <div class="form-group">
                <label :for="$t('kontak_ons.form.email')">{{ $t('kontak_ons.form.email') }}</label>
                <input type="email" id="email" name="email" class="form-control" required />
              </div>
              <div class="form-group">
                <label :for="$t('kontak_ons.form.message')">{{ $t('kontak_ons.form.message') }}</label>
                <textarea id="message" name="message" class="form-control" rows="3" required></textarea>
              </div>
              <div style="display:none;">
                <input type="text" name="botspot" id="botspot" value="" />
              </div>
              <button type="submit" class="btn btn-brand bg-white text-brand-primary mt-3">{{ $t('kontak_ons.form.submit')
              }}</button>
            </form>
          </div>



        </div>
      </div>
    </div>
  </section>
</template>

<script>

</script>
