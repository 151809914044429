<template>
  <div class="ontmoet-die-span text-brand-primary" id="ontmoetdiespan">
    <h1 class="text-center p-5">{{ $t('ontmoet_die_span.title') }}</h1>
    <div class="row p-3">
      <div class="col text-center">
        <img :src="require('@/assets/img/span_img.png')" alt="Schalk Burger Logo" class="img-fluid " />
      </div>
      
    </div>
    <div class="container-fluid">
      <div>
      <swiper :slidesPerView="3" :spaceBetween="5" :freeMode="true" :pagination="{
        clickable: true,
      }" :modules="modules" class="mySwiper">
        <swiper-slide v-for="(slide, index) in slides" :key="index" class="text-center p-3">
          <img :src="slide.image" :alt="slide.name" class="img-fluid" />
          <div class="pt-3">
            <h6>{{ slide.name }}</h6>
          <p class="fs-6">{{ $t(slide.title) }}</p>
          
          </div>
          
        </swiper-slide>
        
      </swiper>
    </div>

    </div>

    
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Pagination, Navigation } from 'swiper/core'; // Import SwiperCore and required modules
import 'swiper/css';
import 'swiper/swiper-bundle.css';
import 'swiper/css/pagination';
import 'swiper/css/navigation'; // Import the navigation styles
SwiperCore.use([Pagination, Navigation]); // Install the required modules
export default {
  name: 'OntmoetDieSpanAfr',
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 3,
        spaceBetween: 6,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
      
  
      slides: [
        {
          name: "Victor Matfield",
          title: "ontmoet_die_span.slides.0.title",
          email: "victorm@schalkburger.co.za",
          image: require('@/assets/img/span1.png'),
        },
        {
          name: "Corne Oberholzer",
          title: "ontmoet_die_span.slides.1.title",
          email: "victorm@schalkburger.co.za",
          image: require('@/assets/img/span2.png'),
        },
        {
          name: "Werner van der Merwe",
          title: "ontmoet_die_span.slides.2.title",
          email: "victorm@schalkburger.co.za",
          image: require('@/assets/img/span3.png'),
        },
        {
          name: "Nathan Vienings",
          title: "ontmoet_die_span.slides.3.title",
          email: "victorm@schalkburger.co.za",
          image: require('@/assets/img/span4.png'),
        },
        {
          name: "Nico Britz",
          title: "ontmoet_die_span.slides.4.title",
          email: "victorm@schalkburger.co.za",
          image: require('@/assets/img/span5.png'),
        },
        {
          name: "Andre Coetzee",
          title: "ontmoet_die_span.slides.5.title",
          email: "victorm@schalkburger.co.za",
          image: require('@/assets/img/span6.png'),
        },
        {
          name: "Johannes Phale",
          title: "ontmoet_die_span.slides.6.title",
          email: "victorm@schalkburger.co.za",
          image: require('@/assets/img/span7.png'),
        },
        {
          name: "Schalk Burger",
          title: "ontmoet_die_span.slides.7.title",
          email: "victorm@schalkburger.co.za",
          image: require('@/assets/img/span8.png'),
        },
        {
          name: "Marius Joone",
          title: "ontmoet_die_span.slides.8.title",
          email: "victorm@schalkburger.co.za",
          image: require('@/assets/img/span9.png'),
        },
        {
          name: "Annelie van Greunen",
          title: "ontmoet_die_span.slides.9.title",
          email: "victorm@schalkburger.co.za",
          image: require('@/assets/img/span10.png'),
        },
        {
          name: "Theo Oberholzer",
          title: "ontmoet_die_span.slides.10.title",
          email: "victorm@schalkburger.co.za",
          image: require('@/assets/img/span11.png'),
        },
      ],
    };
  },
  setup() {
    return {
      modules: [ Pagination, Navigation], // Add the Navigation module here
    };
  },
};
</script>

<style scoped>
/* Custom styles for the swiper component */

@media (max-width: 576px) {
  .mySwiper .swiper-slide h6 {
    font-size: 14px;
    margin-top: 8px;
  }
}
</style>