import { createApp } from 'vue';
import App from './App.vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '@/assets/css/brand-styles.css';
import VueScrollTo from 'vue-scrollto';
import 'bootstrap-icons/font/bootstrap-icons.css';
import VueGtag from "vue-gtag";
import { createI18n } from 'vue-i18n'; // Update the import statement

// Import your translation files
import afMessages from './locales/af.json';
import enMessages from './locales/en.json';

// Set up i18n
const i18n = createI18n({ // Update the initialization
  locale: 'Afrikaans', // Set the default language to Afrikaans
  fallbackLocale: 'Afrikaans', // Fallback to Afrikaans if a translation is not available in English
  messages: {
    Afrikaans: afMessages,
    English: enMessages
  }
});

const app = createApp(App);
app.use(VueScrollTo);
app.use(i18n);
app.use(VueGtag, {
  config: { 
    id: "G-W42TZ80WRQ"}});
app.mount('#app');
