<template>
  <footer>
    <div class="container p-3 text-brand-primary text-center ">
      <div class="row justify-content-md-center ">
        <div class="col-md ">
          <img :src="require('@/assets/img/schalk_logo.png')" alt="Schalk Burger Logo" class="img-fluid w-50" />
        </div>
        <div class="col-md p-4 ">
         
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p>&copy; {{ currentYear }}: {{ $t('footer.copyright') }}</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped>
/* Add the following CSS rules */
.social-icons {
  list-style: none;
  display: flex;
  padding: 0;
}

.social-icons li {
  margin-right: 10px;
}
/* End of added CSS rules */
</style>

<script>
export default {
  name: 'FooterAfr',
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>
